import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import DraggableListPage from './pages/DraggableListPage';
import CombinedRanking from './pages/CombinedRanking';
const App = () => {
    return (
        <Router>
            <Routes>

                <Route path="/choco" element={<DraggableListPage />} />
                <Route path="/rank" element={<CombinedRanking />} />
                {/* Add other routes here */}
                <Route path="*" element={<h1>Not Found</h1>} />
            </Routes>
        </Router>
    );
};

export default App;