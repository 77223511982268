import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import './CombinedRanking.css';

function CombinedRanking() {
  const [rowData, setRowData] = useState([]);

  const columnDefs = [
    { headerName: "Description", field: "description" },
    { headerName: "Ranking", field: "ranking" },
    { headerName: "Combined Points", field: "combinedPoints" }
  ];

  useEffect(() => {
    axios.get(process.env.REACT_APP_SPRING_API_URL + '/getFlavourRanking')
      .then(response => {
        setRowData(response.data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);

  return (
    <div className="ag-theme-alpine" style={{ height: 400, width: 600 }}>
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
      />
    </div>
  );
}

export default CombinedRanking;