import React, { useState, useEffect, useMemo } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import './DraggableListPage.css';
import Modal from 'react-modal';

Modal.setAppElement('#root');

function DraggableListPage() {
  const [items, setItems] = useState([]);
  const [username, setUsername] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(true); // Set isModalOpen to true
  const [isLoading, setIsLoading] = useState(true); // Add isLoading state

  useEffect(() => {
    if (username) {
      setIsLoading(false); // Set isLoading to false when username is set
      fetch(process.env.REACT_APP_SPRING_API_URL + '/getFlavoursForUser?username=' + username.toLowerCase())
        .then(response => response.json())
        .then(data => {
          const sortedData = data.sort((a, b) => a.vieworder - b.vieworder);
          setItems(sortedData);
        })
        .catch(error => console.error('Error:', error));
    }

    window.addEventListener('beforeunload', () => setItems([]));

    return () => {
      window.removeEventListener('beforeunload', () => setItems([]));
    };
  }, [username]);



  const handleModalClose = (username) => {
    setUsername(username);
    setIsModalOpen(false);
  };
  const onDragEnd = (result) => {
     if (!result.destination) {
       return;
     }

     const newItems = Array.from(items);
     const [relocatedItem] = newItems.splice(result.source.index, 1);
     newItems.splice(result.destination.index, 0, relocatedItem);

     const lowerIndex = Math.min(result.source.index, result.destination.index);
     const updatedItems = [];

     newItems.slice(lowerIndex).forEach((item, index) => {
       item.vieworder = lowerIndex + index + 1;
       updatedItems.push({
         id: item.id,
         vieworder: item.vieworder
       });
     });

     // Optimistically update the UI by setting the temporary state
     setItems(newItems);

     // Send request to update view orders
     fetch(process.env.REACT_APP_SPRING_API_URL + '/updateViewOrders', {
       method: 'PUT',
       headers: {
         'Content-Type': 'application/json',
       },
       body: JSON.stringify(updatedItems),
     })
     .then(response => {
       if (response.ok) {
         // Do nothing, as the state is already updated optimistically
       } else {
         throw new Error('Server response was not ok.');
       }
     })
     .catch((error) => {
       // If there's an error, revert back to the original state
       setItems(items);
       console.error('Error:', error);
     });
   };

  // Add a new state for the random line
  const [randomLine, setRandomLine] = useState('');

  // Define an array of 10 lines using useMemo
  const lines = useMemo(() => [
    'heard you liked dark chocolates 🤠',
    'choco wisely! 🍫',
    'Tigris is totally judging you right now 🐯',
    'Welcome to the dark side... of the chocolate force! 🌚',
    'this ad is brought to you by Microsoft 🌐',
    'Mom\'s spaghetti 🍝 > dark chocolate ?',
    'What\'s up doc? 🐰🥕',
    'Eminem is not the best rapper alive! That would be Kendrick 🎤',
    'Visca Barca! 🔴🔵',
    'Welcome comrade Tahiralov! 🇷🇺',
    'Never knew that pasta was a norwegian dish! 🤔🍝',
    'May the cocoa be with you, young padawan! 👴👧',
    'Chocolate cravings: Resistance is futile. 🍫😋',
    'Hakuna Matata! 🦁👑',
  ], []); // Pass an empty array as the dependency array to useMemo

  useEffect(() => {
    // Check if lines is defined and not empty
    if (lines && lines.length) {
      // Generate a random index for the array
      const randomIndex = Math.floor(Math.random() * lines.length);
      // Select a random line from the array and set the randomLine state
      setRandomLine(lines[randomIndex]);
    }
  }, [lines]); // Pass lines as a dependency to useEffect


return (
  <div className="draggable-list-page">
    <Modal isOpen={isModalOpen} className="Modal">
      <div className="ModalContent">
        <form onSubmit={(e) => {
          e.preventDefault();
          handleModalClose(e.target.elements.username.value);
        }}>
          <h2 className="ModalTitle">Enter Username</h2>
          <label>
            <input type="text" name="username" required className="UsernameInput" />
          </label>
          <button type="submit" className="SubmitButton">Submit</button>
        </form>
      </div>
    </Modal>
          {!isLoading && username && (
                  <div className="info-box">
                    <div>{randomLine}</div> {/* Display the random line */}
                  </div>
                )}
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div className="list-container" {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item, index) => (
  <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
    {(provided) => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className="list-item" // Add a class name for styling
      >
        <span className="item-description">{item.description}</span>
        <span className="item-medal">{index === 0 ? '🥇' : index === 1 ? '🥈' : index === 2 ? '🥉' : ''}</span>
      {index === items.length - 1 ? <span className="item-medal">🙃</span> : null}
      </div>
    )}
  </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  </div>
  );
}

export default DraggableListPage;